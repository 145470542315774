<template>
  <div class="attention baseConsoleTabPage" v-loading="Loading">
    <div class="tabBlock">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('CollectionEnterprise')"
          name="CollectionEnterprise"
        ></el-tab-pane>
        <template v-if="!BACCONFIG.accountConfig.notFavorite">
          <el-tab-pane
            :label="$t('CollectionMembers')"
            name="CollectionMembers"
          ></el-tab-pane>
        </template>
        <el-tab-pane
          :label="$t('CollectionVideo')"
          name="collectedVideo"
        ></el-tab-pane>
        <template v-if="!BACCONFIG.accountConfig.notFavorite">
          <el-tab-pane
            :label="$t('collectedMe')"
            name="collectedMe"
          ></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="tableBlock">
      <el-table
        v-if="activeName == 'CollectionEnterprise'"
        :data="TableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
      >
        <!--收藏企业---收藏企业名称 -->
        <el-table-column :label="$t('NameOfCollectionEnterprise')">
          <template slot-scope="scope">
            <el-link
              class="mainColor"
              type="primary"
              :underline="false"
              @click="toDetail(scope.row)"
            >
              {{
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </el-link>
          </template>
        </el-table-column>
        <!-- 收藏企业---国家 -->
        <el-table-column
          width="200"
          show-overflow-tooltip
          :label="$t('country')"
          prop="country"
        >
          <template slot-scope="scope">{{
            scope.row.country | countryFormat(LOCALE)
          }}</template>
        </el-table-column>
        <!-- 收藏企业---地址 -->
        <el-table-column :label="$t('region')">
          <template slot-scope="scope">
            {{
              scope.row.company_region_en
                | priorFormat(scope.row.company_region_zh, LOCALE)
            }}
          </template>
        </el-table-column>
        <!-- 收藏企业---收藏时间 -->
        <el-table-column :label="$t('CollectionTime')">
          <template slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </template>
        </el-table-column>
        <div slot="empty">
          <p style="color: #909399">{{ $t("nodata") }}</p>
        </div>
      </el-table>
      <!--        收藏视频-->
      <el-table
        v-if="activeName == 'collectedVideo'"
        :data="TableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
      >
        <!-- 视频名称 -->
        <el-table-column :show-overflow-tooltip="true" :label="$t('videoName')">
          <template slot-scope="scope">
            <div class="videoImgBox">
              <el-image
                :fit="'cover'"
                class="videoImg"
                :src="scope.row.cover_url"
              ></el-image>
              <div class="mark">
                <i
                  class="el-icon-video-play"
                  @click="handleVideo(scope.row)"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 收藏时间 -->
        <el-table-column :label="$t('visitorTime2')">
          <template slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column :label="$t('handle')" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleUnfavorite(scope.row)"
              :loading="scope.row.loading"
              >{{ $t("cancelCollection") }}</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty">
          <p style="color: #909399">{{ $t("nodata") }}</p>
        </div>
      </el-table>

      <!-- *************************分割线上面的收藏企业  下面是收藏成员&&谁收藏了我****************************** -->
      <el-table
        v-if="activeName == 'CollectionMembers' || activeName == 'collectedMe'"
        :data="TableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
      >
        <!-- 收藏成员名称 -->
        <el-table-column
          :show-overflow-tooltip="true"
          width="200"
          :label="$t('CollectionMembers')"
        >
          <template slot-scope="scope">
            <!--  弹出框 -->
            <el-popover
              placement="top-start"
              width="400"
              trigger="click"
              popper-class="talkone"
            >
              <div class="company">
                <div
                  class="whiteColor"
                  :title="
                    scope.row.company_name_en
                      | priorFormat(scope.row.company_name_zh, LOCALE)
                  "
                >
                  {{
                    scope.row.company_name_en
                      | priorFormat(scope.row.company_name_zh, LOCALE)
                  }}
                </div>
              </div>
              <div class="info clearfix">
                <div class="infoLeft fl">
                  <div class="whiteColor user_name">
                    <span
                      :title="
                        scope.row.name_en
                          | priorFormat(scope.row.name_zh, LOCALE)
                      "
                      >{{
                        scope.row.name_en
                          | priorFormat(scope.row.name_zh, LOCALE)
                      }}</span
                    >
                  </div>
                  <div class="whiteColor marginT2">
                    <div class="clearfix">
                      <el-image class="fl" :src="phoneImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.mobile | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1">
                      <el-image class="fl" :src="emailImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.email | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'zh'">
                      <el-image class="fl" :src="wechatImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.wechat | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'en'">
                      <el-image class="fl" :src="faceImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.facebook | textFormat }}
                      </div>
                    </div>
                  </div>
                  <div class="whiteColor country">
                    {{
                      scope.row.country
                        | countryCityFormat(
                          scope.row.country_name_en,
                          scope.row.country_name_zh,
                          LOCALE
                        )
                    }}
                    {{
                      scope.row.country
                        | countryCityFormat(
                          scope.row.city_name_en,
                          scope.row.city_name_zh,
                          LOCALE
                        )
                    }}
                  </div>
                </div>
                <div class="infoRight fr tc">
                  <el-image
                    :src="scope.row.avatar ? scope.row.avatar : default_img"
                    class="avatar"
                  ></el-image>
                  <div
                    class="marginT1"
                    :title="
                      scope.row.job_title_en
                        | priorFormat(scope.row.job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.job_title_en
                        | priorFormat(scope.row.job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
              <span slot="reference" class="cursor">
                {{ scope.row.name_en | priorFormat(scope.row.name_zh, LOCALE) }}
              </span>
            </el-popover>
          </template>
        </el-table-column>

        <!--所属公司 Company 所属公司 公司名称-->
        <el-table-column
          :label="
            activeName == 'CollectionMembers'
              ? $t('Company')
              : $t('dylancompanyName')
          "
        >
          <template slot-scope="scope">
            <div
              class="textOverflow"
              :title="
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              "
            >
              {{
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 职位 -->
        <el-table-column
          :label="$t('position')"
          width="230"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
              scope.row.job_title_en
                | priorFormat(scope.row.job_title_zh, LOCALE)
            }}
          </template>
        </el-table-column>
        <!-- 收藏时间 -->
        <el-table-column :label="$t('visitorTime2')" width="280">
          <template slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          :label="$t('handle')"
          width="200"
          v-if="activeName == 'CollectionMembers'"
        >
          <template slot-scope="scope">
            <!-- <agendaDialog :companyInfo="companyInfo">
              <el-link type="primary" :underline="false" @click="handleSubscribe(scope.row)">
                {{ $t('meetingAppointment') }}
              </el-link>
            </agendaDialog> -->
            <!-- 预约 -->
            <p
              v-if="PJSource !== 12 && PJSource !== 20"
              class="btn_button"
              @click="handclick(scope.row)"
            >
              <span class="cursor mainColor">
                {{ $t("meetingAppointment") }}
              </span>
            </p>
            <!-- 取消 -->
            <p class="btn_button" @click="handCancel(scope.row)">
              <span class="cursor mainColor">
                {{ $t("cancelCollection") }}
              </span>
            </p>
          </template>
        </el-table-column>
        <div slot="empty">
          <p style="color: #909399">{{ $t("nodata") }}</p>
        </div>
      </el-table>

      <div class="paginationBlock">
        <el-pagination
          layout="total, prev, pager, next"
          :total="Total"
          :current-page.sync="CurrentPage"
          :page-size="10"
        >
        </el-pagination>
      </div>
    </div>
    <videoPlay ref="allvideoplay" />
    <orderFreeDialog
      @dataChange="_dataChange"
      :orderVisible="orderVisible"
      @orderVisibleShow="_orderVisibleShow"
    ></orderFreeDialog>
  </div>
</template>
<script>
import orderFreeDialog from "@/baseComponents/orderFreeDialog";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: {
    orderFreeDialog,
    videoPlay,
  },
  data() {
    return {
      activeName: "CollectionEnterprise",
      companyInfo: {},
      membermsg: {},
      TableData: [],
      Loading: false,
      orderVisible: false,
      Total: 0,
      CurrentPage: 1,
      phoneImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/phone.png",
      emailImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/email.png",
      faceImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/facebook.png",
      wechatImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/wechat.png",
      default_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/avatar.png",
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    CurrentPage() {
      this.getList();
    },
    TableData() {
      console.log(this.TableData);
    },
  },
  methods: {
    handclick(row) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (
        this.USER_INFO.company_bind_status == 0 ||
        !this.USER_INFO.company_id
      ) {
        //没有绑定公司
        this.$message.warning(this.$t("company_information"));
        return;
      }
      console.log("111111111");
      this.membermsg = row;
      this.orderVisible = true;
    },
    // 取消收藏
    handCancel(row) {
      this.$confirm(this.$t("sure_cancle_cllect"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let params = {
            user_id: this.USER_INFO.id,
            related_id: row.user_id,
            collection_type: 2, //收藏类型：1-公司，2-用户
            source: this.PJSource,
            siffa_collection_id: row.siffa_collection_id,
            deleted: 1,
          };

          let res = await this.$store.dispatch(
            "API_company/favoritesCompany",
            params
          );
          if (res && res.success) {
            this.getList();
          }
        })
        .catch(() => {});
    },
    //tabs 切换事件
    handleClick(tab, event) {
      this.CurrentPage = 1;
      this.getList();
    },
    async _dataChange(data) {
      let { start_time, end_time, day, send_postscript } = data;
      let params = {
        send_user_id: this.USER_ID, //
        send_company_id: this.USER_INFO.company_id, //
        in_user_id: this.membermsg.user_id,
        in_company_id: this.membermsg.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.company_id, //
        start_time,
        end_time,
        day,
        send_postscript,
        source: this.PJSource,
      };

      let res = await this.$store.dispatch("API_company/companyInvite", params);

      this.orderVisible = false;
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"));
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"));
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"));
      } else {
      }
    },
    // 请求数据
    async getList() {
      this.Loading = true;
      this.TableData = [];
      let params = {};
      params.start = this.CurrentPage - 1;
      params.limit = 10;
      params.nopage = 0;
      params.source = this.PJSource;
      //  收藏企业
      if (this.activeName == "CollectionEnterprise") {
        params.user_id = this.USER_ID || -1;
        //测试id params.user_id=52307
        params.collection_type = 1; //收藏类型：1-公司，2-用户
      }
      //收藏成员
      if (this.activeName == "CollectionMembers") {
        params.user_id = this.USER_ID || -1;
        params.collection_type = 2; //收藏类型：1-公司，2-用户
      }
      // 谁收藏了我
      if (this.activeName == "collectedMe") {
        params.collection_type = 2;
        params.related_id = this.USER_ID; //谁收藏了用户，必传， 传当前用户id  52317
      }
      //收藏视频
      if (this.activeName == "collectedVideo") {
        params.user_id = this.USER_ID || -1;
      }
      try {
        let res;
        if (this.activeName == "collectedVideo") {
          res = await this.$store.dispatch(
            "baseConsole/getCollectionVideosList",
            params
          );
        } else {
          res = await this.$store.dispatch(
            "baseConsole/getCollectionMemberList",
            params
          );
        }
        console.log(res);
        if (res.success) {
          if (this.activeName == "collectedVideo" && res.data.length > 0) {
            res.data.forEach((item) => {
              item.loading = false;
            });
          }
          this.TableData = res.data;
          this.Total = res.total;
          this.Loading = false;
        }
      } catch (error) {
        this.Loading = false;
      }
    },
    _orderVisibleShow(data) {
      this.orderVisible = false;
    },
    // 预约事件
    handleSubscribe(row) {
      this.companyInfo.user_id = row.user_id;
      this.companyInfo.company_id = row.company_id;
    },
    //公司详情
    toDetail(row) {
      // this.goToCompanyPage({
      //   company_id: row.company_id,
      // });
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: row.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    async handleUnfavorite(row) {
      row.loading = true;
      let params = {
        like_type: 0,
        video_id: row.id,
        user_id: this.USER_ID || -1,
      };
      let res = await this.$store.dispatch(
        "baseConsole/handleAssociationVideoLike",
        params
      );
      row.loading = false;
      if (res.success) {
        this.$message.success(this.$t('Successful_operation'));
        this.getList();
      }
    },
    handleVideo(row) {
      this.$refs.allvideoplay.startPlay(row.aliyun_id);
      this.$store.dispatch("baseConsole/handlePlayVideoCountAdd", {
        video_id: row.id,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  margin-top: 10px;
  text-align: right;
}

.cursor {
  color: rgb(0, 131, 246);
  cursor: pointer;
}

.company {
  height: 25px;

  div {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  div:nth-child(2) {
    color: #666666;
  }
}

.avatar {
  width: 73px;
  height: 73px;
  border-radius: 50%;
}

.marginTB {
  margin-bottom: 10px;
}

.info {
  margin-top: 15px;

  div,
  span {
    color: #fff;
  }

  .infoLeft {
    width: 70%;

    .user_name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .infoRight {
    width: 30%;
    margin: 0 auto;

    div:nth-child(2) {
      max-height: 100px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  .country {
    margin-top: 18px;
  }
}

.marginL {
  margin-left: 10px;
}

.marginT {
  margin-top: 30px;
}

.marginT1 {
  margin-top: 3px;
}

.marginT2 {
  margin-top: 10px;
}

.posirion-wrap {
  position: relative;
}

.videoImgBox {
  position: relative;
  width: 140px;
  height: 88px;
  .videoImg {
    width: 140px;
    height: 88px;
  }
  .mark {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 140px;
    height: 88px;
    text-align: center;
    line-height: 88px;
    .el-icon-video-play {
      font-size: 20px;
      color: white;
      cursor: pointer;
    }
  }
}
/deep/ .active.el-table::before {
  display: none;
}
</style>