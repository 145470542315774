<template>
  <div class="videoPlayComponents">
    <div>
      <slot></slot>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="950px"
      class="videoPlayDialog"
      :modal="showModal"
      :destroy-on-close="true"
      append-to-body
      @close="close"
    >
      <div style="height: 500px">
        <div v-if="dialogVisible" id="J_prismPlayer" style="height: 100%"></div>
      </div>
    </el-dialog>
    <script type="text/template" id="endPreviewTemplate">
      <div class="endPreviewTemplate">
        <div>
           <div class="tips">试看已结束，如需观看全部视频，请联系秘书处：</div>
            <div class="scbaContactInfo">
              <div class="concatItem">
                入会联系：021-68892891
                </div>
                <div class="concatItem">
                业务咨询：021-68892892
                </div>
                <div class="concatItem">
                培训联系：021-68892894
                </div>
                <div class="concatItem">
                赞助联系：021-68892893
                </div>
                <div class="concatItem">
                跨境贸易分会联系：021-20953528
                </div>
                <div class="concatItem">
                空运分会联系：021-58102609
                </div>
            </div>
            <div class="knowBtn" data-click data-method="closePreviewTip">我知道了</div>
        </div>
      </div>
    </script>
    <script type="text/template" id="previewBarHtml">
      <span>如需观看全部视频，请联系秘书处</span>
    </script>
  </div>
</template>


<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    perviewTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      videoId: "",
      pre_view_time: 0,
      player:null
    };
  },
  mounted() {
    if (this.PJSource == 13) {
      this.pre_view_time = 180;
    }
  },
  methods: {
    close(){
      this.player=null
    },
    async handlePlay() {
      this.dialogVisible = true;
      // var videoAdClose = function (videoAd) {
      //   videoAd.pauseVideoAd();
      //   videoAd.closeVideoAd();
      // };
      try {
        let result = await this.$store.dispatch("baseStore/getPlayAuth", {
          aliyun_id: this.videoId,
        });
        if (result.success) {
          //运去哪视频：https://vod.aila.site/customerTrans/bcd69f5462af799806a3517fab306996/2afd54b7-1771489a99f-0006-8568-b09-239a7.mp4
           this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              language: this.IS_EN ? "en-us" : "zh-cn",
              vid: result.data.VideoMeta.VideoId,
              playauth: result.data.PlayAuth,
              cover: result.data.VideoMeta.CoverURL,
              components: [
                {
                  name: "VideoADComponent",
                  type: AliPlayerComponent.VideoADComponent,
                  args: [
                    "https://vod.aila.site/customerTrans/bcd69f5462af799806a3517fab306996/34abc835-18f4bb1aa0d-0006-8568-b09-239a7.mp4",
                    "",
                    false,
                    this.$t("clickClose"),
                  ],
                },
                {
                  name: "PreviewVodComponent",
                  type: AliPlayerComponent.PreviewVodComponent,
                  args: [
                    this.pre_view_time,
                    `#endPreviewTemplate`,
                    "#previewBarHtml",
                  ],
                },
              ],
            },
            (player) => {
              let eventDoms = document.querySelectorAll("[data-click]");
              eventDoms.forEach((item) => {
                item.addEventListener("click", () => {
                  if (item.getAttribute("data-method") == "closePreviewTip") {
                    player
                      .getComponent("PreviewVodComponent")
                      .closePreviewLayer();
                  } else {
                    this.$emit(item.getAttribute("data-method"));
                  }
                });
              });
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    startPlay(videoId) {
      // if (JSON.stringify(this.USER_INFO) == "{}") {
      //   this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      //   if (this.PJSource == 10 || this.PJSource == 11) {
      //     this.$router.push("/sign");
      //   }
      //   return;
      // }
      this.videoId = videoId;
      this.handlePlay();
    },
  },
};
</script>

<style lang="less" scoped>
.videoPlayComponents {
  /deep/.videoPlayDialog {
    z-index: 3300 !important;
    // .el-dialog__body {
    //   padding: 0px;
    //   .playbox {
    //     height: 500px;
    //     .prism-player {
    //       height: 100%;
    //     }
    //   }
    // }
  }
}
/deep/.el-dialog__body {
  padding: 0px;
}
</style>
<style lang="less">
.scbacontacttips {
  color: #00c1de;
  margin-left: 2px;
}
.endPreviewTemplate {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .scbaContactInfo {
    margin: 0 auto;
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .concatItem {
      width: 230px;
      font-size: 14px;
      color: #fff;
    }
  }
  .knowBtn {
    width: 120px;
    padding: 7px 0px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-top: 15px;
    cursor: pointer;
  }
}
</style>
<style  lang='less'>
.video-ad-component .video-ad-detail {
  display: none !important;
}
</style>
